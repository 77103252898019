



































































import { mapGetters } from "vuex";
import * as MySAL from "@/utils/msal";
import IconBurger from "@/icons/bars-solid.svg";
import { userIsClient } from "@/app/helpers";

export default {
  name: "App",
  components: { IconBurger },
  data() {
    return {
      menuOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      loading: "App/loading/loading",
      wrongRedmineUser: "Resource/auth/wrongRedmineUser",
      user: "Resource/auth/user",
      versionMismatch: "Dashboard/list/versionMismatch",
      projects: "Project/list/projects",
      notif: "Dashboard/list/notif",
      lastNotif: "Dashboard/list/lastNotif",
      ignoredNotif: "Dashboard/list/ignoredNotif",
    }),
    redirecting() {
      return MySAL.redirecting;
    },
    authorized() {
      return !!this.user;
    },
    showNotifBadge() {
      return false;
      // if (!userIsAtLeastAccountant(this.user)) return false;
      if (userIsClient(this.user)) return false;
      if (this.ignoredNotif) return false;
      if (!this.notif) return false;
      return this.lastNotif != this.notif.toString();
    },
  },
  methods: {
    reload() {
      window.location.reload(true);
    },
    logout() {
      MySAL.logout();
    },
    closeMenu() {
      this.menuOpen = false;
    },
  },
};
