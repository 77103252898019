import Vue from 'vue';
import Vuex from 'vuex';
import Resource from './modules/resource';
import Issue from './modules/issue';
import App from './modules/app';
import TimeEntry from './modules/time-entry';
import Project from './modules/project';
import Enumeration from './modules/enumeration';
import Marker from './modules/marker';
import Credit from './modules/credit';
import Billing from './modules/billing';
import Dashboard from './modules/dashboard';
import Group from './modules/group';
import KPI from './modules/kpi';
import Crud from './modules/crud';
import Skill from './modules/skill';
import TeamTime from './modules/team-time';
import ProjectTime from './modules/project-time';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        Resource,
        Issue,
        App,
        TimeEntry,
        Project,
        Enumeration,
        Marker,
        Credit,
        Billing,
        Dashboard,
        Group,
        KPI,
        Crud,
        Skill,
        TeamTime,
        ProjectTime,
    }
})
