import { fetchCube } from '@/utils/fetch';
import {
    PROJECTTIME_FOR_PROJECT,
} from '@/config/cube-routes';
import { } from './mutation-types';


const actions = {
    userForProject({ }, { projectId, params }) {
        const url = PROJECTTIME_FOR_PROJECT.replace('#projectId#', projectId)
        return fetchCube(url, { params }).then(response => response.data);
    }
};


const mutations = {
};


export default {
    namespaced: true,
    actions,
    mutations,
}
