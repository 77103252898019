export const GET_PROJECTS = '/projects';
export const GET_PROJECTS_DETAIL = '/projects/detail';
export const GET_PROJECT_DETAIL = '/projects/#projectId#/detail';
export const GET_PROJECT_TOTAL = '/project/#projectId#/totals';
export const GET_PROJECT_TOTALS_CACHED = '/project/#projectId#/totals-cached';
export const GET_ISSUE = '/issue/#issueId#';
export const GET_ISSUES = '/issues.#format#';
export const PECC_TO_SOLD = '/pecc-to-sold';
export const GET_TIME_ENTRIES = '/time_entries.json';
// export const GET_LOG_TIME_ENTRY_UPDATE = '/time_entries/logs'; // Deprecated
export const GET_LOG_TIME_ENTRY = '/time_entries/log/#id#'; // Used by TimeModal
export const GET_PUBLIC_HOLIDAYS = '/public_holidays.json';
export const GET_MARKERS = '/markers/#resourceId#.json';
export const GET_CREDITS = '/credits';
export const GET_VERSIONS_CREDITS = '/versions_credits';
export const GET_CREDITS_CUSTOMERS = '/credits/customers';
export const GET_CREDITS_OPPORTUNITIES = '/credits/opportunities/#customerId#';
export const GET_CREDITS_LINES = '/credits/lines/#customerId#/#opportunityId#';
export const EDIT_VERSION_CREDIT = '/versions_credits/edit/#vcreditId#';
export const DELETE_VERSION_CREDIT = '/versions_credits/delete/#vcreditId#';
export const SET_ISSUE_EXTRA = '/issue/update/extra/#issueId#';
export const SET_ISSUE_ORDER = '/issue/update/order';
export const GET_TIME_ENTRIES_FOR_ISSUE = '/time_entries/issue/#issueId#';
export const SET_PROJECT_EXTRA = '/project/update/extra/#projectId#';
export const GET_PROFILES_FOR_VERSION = '/versions_credits/profiles/#versionId#';
export const GET_PROFILES_USED_FOR_ISSUE = '/versions_credits/profiles/issue/#issueId#';
export const GET_PROFILES_USED_FOR_VERSION = '/versions_credits/profiles/version/#versionId#';
export const GET_VCRS_FOR_VERSION = '/versions_credits/vcrs/version/#versionId#';
export const SET_TIME_ENTRY_EXTRA = '/time_entry/remove-create/extra';
export const REMOVE_TIME_ENTRY_EXTRA = '/time_entry/remove/extra';
export const RETRIEVE_CREDIT_LINES = '/versions_credits/retrieve/credit_lines';
export const RETRIEVE_CUSTOMERS = '/versions_credits/retrieve/customers';
export const GET_BILLING_MARKERS = '/billing_markers';
export const EDIT_BILLING_MARKERS = '/billing_markers/edit/#bmarkerId#';
export const DELETE_BILLING_MARKERS = '/billing_markers/delete/#bmarkerId#';
export const RETRIEVE_BILLING_MARKERS = '/billing_markers/retrieve';
export const GET_BILLING_TIMELINE = '/billing_markers/timeline/#projectId#';
export const GET_TIME_ENTRIES_REPORT = '/time_entries/report';
export const GET_TIME_ENTRIES_REPORT_FILE = '/time_entries/report/file';
export const GET_TIME_ENTRIES_REPORT_SUM = '/time_entries/report/sum';
export const MOVE_TIME_ENTRIES = '/time_entries/move/#fromIssueId#/#toIssueId#';
export const MAP_PROFILE_TIME_ENTRIES = '/time_entries/map-profiles/#issueId#';
export const GET_TIME_ENTRIES_FOR_PROFILECHANGER = '/time_entries/profile-changer';
export const UPDATE_TIMEENTRY_PROFILES = '/time_entries/update-profiles';
export const GET_ISSUE_JOURNALS = '/issue/journals';
export const GET_TIME_ENTRY_LOGS = '/time_entries/logs';
export const SEARCH_CUSTOMERS = '/credits/customers/search';
export const GET_LEAVES = '/dashboard/leaves/#mode#';
export const GET_LEAVE_CURSORS = '/dashboard/leaves/cursors';
export const GET_LEAVES_COUNTERS = '/dashboard/leaves/counters';
export const SET_LEAVES_STATUS = '/dashboard/leaves/status';
export const HEARTBEAT = '/dashboard/heartbeat';
export const ISSUE_FOR_RESOURCE = '/issues-for-resource/#resourceId#';
export const COUNT_FOR_RESOURCE_BY_PROJECT = '/count-for-resource-by-project/#resourceId#';
export const COUNT_PROBLEMS = '/dashboard/problems';
export const GET_PROBLEMS = '/dashboard/problems/#type#';
export const GET_MY_ENTRIES = '/dashboard/entries/{userIds}/{from}/{to}';
export const GET_MY_ENTRIES_FOR_PROJECT = '/dashboard/entries/{userIds}/{projectId}/{from}/{to}';
export const GET_MY_NUMBERS = '/dashboard/numbers/{userIds}/{from}/{to}.{format}';
export const GET_USERS = '/users';
export const GET_ME = '/me';
export const SET_USER_EXTRA = '/user/update/extra/#userId#';
export const GET_USER_NOTES = '/user/notes/#userId#';
export const GET_USER_BOOKMARKS = '/user/bookmarks';
export const SEARCH_ISSUES = '/issues/search';
export const COUNT_ISSUE_BY_STATUS = '/kpi/issues-by-status';
export const GET_KPI_SLA_SUPPORT = '/kpi/sla/support';
export const GET_KPI_SLA_RESPONSE = '/kpi/sla/response';
export const GET_KPI_SLA_RESPONSE_DETAIL = '/kpi/sla/response/detail';
export const GET_KPI_VCRS = '/kpi/vcrs';
export const GET_KPI_VCR_VS_SPENT = '/kpi/vcr-vs-spent';
export const GET_KPI_LATEST_ISSUES = '/kpi/latest-issues';
export const CRUD_ACTION = '/crud/#entity#';
export const GET_PROJECT_VERSIONS_USED = '/projects/#projectId#/versions/used';
export const PROJECT_MEMBERS = '/projects/#projectId#/members';
export const REMOVE_PROJECT_MEMBER = '/projects/members/#id#';
export const MULTITASKS_ISSUES = '/issues/multi-tasks';
export const IGNORE_CREDIT = '/credits/ignore/#id#';

export const GET_GROUPS = '/groups';
export const POST_GROUPS = '/groups';
export const PUT_GROUPS = '/groups/#groupId#';
export const DELETE_GROUPS = '/groups/#groupId#';
export const GAME_GROUPS = '/groups/game/#groupId#';
export const GAME_GROUPS_FUTURE = '/groups/game/#groupId#/future';

export const SKILLS = '/skills';
export const USERS_SKILLS = '/skills/users';
export const RENAME_SKILLS_CATEGORY = '/skills/cats/rename';
export const GET_USERS_FOR_SKILLS = '/skills/users-for-skills';

export const TEAMTIME_USER_FOR_GROUP = '/team-time/user/for-group/#groupId#';
export const TEAMTIME_USER_FOR_USER = '/team-time/user/for-user/#userId#';
export const TEAMTIME_PROJECT_FOR_USER = '/team-time/project/for-user/#userId#';
export const TEAMTIME_USER = '/team-time/user';
export const TEAMTIME_PROJECT = '/team-time/project';

export const PROJECTTIME_FOR_PROJECT = '/team-time/user/for-project/#projectId#';
